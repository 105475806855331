import Index from "views/Index.js";
import Profile from "views/examples/Profile.js";
import Login from "views/examples/Login.js";
import Banner from "views/examples/Banner";
import Article from "views/examples/Article";
import Category from "views/examples/Category";
import Brands from "views/examples/Brands";
import Products from "views/examples/Product";
import ProductAdds from "views/examples/ProductAdds";
import ProductEdit from "views/examples/EditProduct";
import ProductPackage from "views/examples/ProductPackage";
// import AllUser from "views/examples/AllUser";
import AllOrder from "views/examples/AllOrder";
import Popup from "components/Popup/popup";
import AllDistributor from "views/examples/AllDistributor";
import AllDistributorOrder from "views/examples/AllDistributorOrder";
import AllDistributorChild from "views/examples/AllDistributorChild";
import AllChild from "views/examples/AllChild";
import AllDistributorTransaction from "views/examples/AllDistributorTransaction";
import AllOrderTransaction from "views/examples/AllOrderTransaction";
import AllDistributorRankHistory from "views/examples/AllDistributorRankHistory";
import AllTransaction from "views/examples/AllTransaction";
import AllRank from "views/examples/AllRank";
import UpdateDistributor from "views/examples/UpdateDistributor";
import AllRankRequest from "views/examples/AllRankRequest";
import Invoice from "views/examples/Invoice";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-dark",
    component: Index,
    layout: "/admin",
    show: true,
  },
  {
    path: "/banner",
    name: "Banner",
    icon: "ni ni-image text-dark",
    component: Banner,
    layout: "/admin",
    show: true,
  },
  {
    path: "/popup",
    name: "Popup",
    icon: "ni ni-image text-dark",
    component: Popup,
    layout: "/admin",
    show: false,
  },

  {
    path: "/article",
    name: "Blogs",
    icon: "ni ni-book-bookmark text-dark",
    component: Article,
    layout: "/admin",
    show: true,
  },

  // {
  //   path: "/all_user",
  //   name: "All User",
  //   icon: "ni ni-circle-08 text-dark",
  //   component: AllUser,
  //   layout: "/admin",
  //   show: true,
  // },
  {
    path: "/all_rank",
    name: "All Rank",
    icon: "ni ni-notification-70 text-dark",
    component: AllRank,
    layout: "/admin",
    show: true,
  },
  {
    path: "/all_rank_request",
    name: "All Rank Request",
    icon: "ni ni-badge text-dark",
    component: AllRankRequest,
    layout: "/admin",
    show: true,
  },
  {
    path: "/all_distributor",
    name: "All Distributor",
    icon: "ni ni-badge text-dark",
    component: AllDistributor,
    layout: "/admin",
    show: true,
  },
  {
    path: "/distributor-order/:id",
    name: "All Distributor",
    icon: "ni ni-badge text-dark",
    component: AllDistributorOrder,
    layout: "/admin",
    show: false,
  },
  {
    path: "/invoice",
    name: "Invoice",
    icon: "ni ni-badge text-dark",
    component: Invoice,
    layout: "/admin",
    show: false,
  },
  {
    path: "/update_distributor/:id",
    name: "All Distributor",
    icon: "ni ni-badge text-dark",
    component: UpdateDistributor,
    layout: "/admin",
    show: false,
  },
  {
    path: "/all_distributor_child",
    name: "All Distributor",
    icon: "ni ni-badge text-dark",
    component: AllDistributorChild,
    layout: "/admin",
    show: false,
  },
  {
    path: "/all_distributor_transaction/:dis_id",
    name: "All Distributor",
    icon: "ni ni-badge text-dark",
    component: AllDistributorTransaction,
    layout: "/admin",
    show: false,
  },
  {
    path: "/all_distributor_rank/:dis_id",
    name: "All Distributor",
    icon: "ni ni-badge text-dark",
    component: AllDistributorRankHistory,
    layout: "/admin",
    show: false,
  },
  {
    path: "/all_child/:parentId",
    name: "All Child",
    icon: "ni ni-badge text-dark",
    component: AllChild,
    layout: "/admin",
    show: false,
  },

  {
    path: "/product-orders",
    name: "Orders",
    icon: "ni ni-bag-17 text-dark",
    component: AllOrder,
    layout: "/admin",
    show: true,
  },
  {
    path: "/orders-transaction/:order_id",
    name: "Orders",
    icon: "ni ni-bag-17 text-dark",
    component: AllOrderTransaction,
    layout: "/admin",
    show: false,
  },
  {
    path: "/transaction",
    name: "Transaction",
    icon: "ni ni-credit-card text-dark",
    component: AllTransaction,
    layout: "/admin",
    show: true,
  },
  // Products
  {
    path: "/product_variant",
    name: "Packages",
    icon: "ni ni-collection text-dark",
    component: ProductPackage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/edit-product",
    name: "Edit Product",
    icon: "ni ni-collection text-dark",
    component: ProductEdit,
    layout: "/admin",
    show: false,
  },
  {
    name: "Product",
    icon: "ni ni-bullet-list-67 text-dark",
    show: true,
    collapse: true,
    views: [
      // {
      //   path: "/filter",
      //   name: "Filter",
      //   icon: "ni ni-ui-04 text-dark",
      //   component: Filter,
      //   layout: "/admin",
      //   show: true,
      // },
      {
        path: "/brand",
        name: "Brand",
        icon: "ni ni-ui-04 text-dark",
        component: Brands,
        layout: "/admin",
        show: true,
      },
      {
        path: "/category",
        name: "Category",
        icon: "ni ni-ui-04 text-dark",
        component: Category,
        layout: "/admin",
        show: true,
      },
      {
        path: "/all-product",
        name: "All Product",
        icon: "ni ni-ui-04 text-dark",
        component: Products,
        layout: "/admin",
        show: true,
      },
      {
        path: "/add-product",
        name: "Add Product",
        icon: "ni ni-ui-04 text-dark",
        component: ProductAdds,
        layout: "/admin",
        show: true,
      },
    ],
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin",
    show: false,
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    show: false,
  },
];
export default routes;
