import http from "./http-common";

class DataService {
  // Login Services
  Login(data) {
    return http.get("admin/login?" + data);
  }
  //popup services
  PopupAdd(data) {
    return http.post(`/pop_up/create`, data);
  }
  PopupUpdate(id, data) {
    return http.patch(`/pop_up/update/${id}`, data);
  }
  PopupDelete(id) {
    return http.delete(`/pop_up/delete/${id}` + id);
  }
  getPop(id) {
    return http.get(`/pop_up/all`);
  }

  // Banner Services
  BannerAll() {
    return http.get(`/banner/admin/all`);
  }
  BannerAdd(data) {
    return http.post(`/banner/create`, data);
  }
  BannerUpdate(id, data) {
    return http.patch(`/banner/update/${id}`, data);
  }
  BannerDelete(id) {
    return http.delete(`/banner/delete/${id}`);
  }

  // Article Services
  ArticleAll() {
    return http.get(`/article/admin/all`);
  }
  ArticleAdd(data) {
    return http.post(`/article/create`, data);
  }
  ArticleUpdate(id, data) {
    return http.patch(`/article/update/${id}`, data);
  }
  ArticleDelete(id) {
    return http.delete(`/article/delete/${id}`);
  }

  // Filter Services
  FilterAll() {
    return http.get(`/filter/admin/all`);
  }
  FilterAdd(data) {
    return http.post(`/filter/create`, data);
  }
  FilterUpdate(id, data) {
    return http.patch(`/filter/update/${id}`, data);
  }
  FilterDelete(id) {
    return http.delete(`/filter/delete/${id}`);
  }

  // Category Services
  CategoryAll() {
    return http.get(`/category/admin/all`);
  }
  CategoryAdd(data) {
    return http.post(`/category/create`, data);
  }
  CategoryAllFilterById(id) {
    return http.get(`/category/id/${id}`);
  }
  CategoryUpdate(id, data) {
    return http.patch(`/category/update/${id}`, data);
  }
  CategoryDelete(id) {
    return http.delete(`/category/delete/${id}`);
  }

  // Brand Services
  BrandAll() {
    return http.get(`/brand/admin/all`);
  }
  BrandAdd(data) {
    return http.post(`/brand/create`, data);
  }
  BrandUpdate(id, data) {
    return http.patch(`/brand/update/${id}`, data);
  }
  BrandDelete(id) {
    return http.delete(`/brand/delete/${id}`);
  }
  // Product Services
  ProductAll() {
    return http.get(`/product/admin/all`);
  }

  ProductAdd(data) {
    return http.post(`/product/add`, data);
  }
  ProductById(id) {
    return http.get(`/product/id/${id}`);
  }
  ProductUpdate(id, data) {
    return http.patch(`/product/edit/${id}`, data);
  }
  ProductDelete(id) {
    return http.delete(`/product/delete/${id}`);
  }

  // Product Packages Services
  ProductAllVariant(id) {
    return http.get(`/product_variant/product/${id}`);
  }
  ProductCreateVariant(data) {
    return http.post(`/product_variant/add`, data);
  }
  ProductUpdateVariant(id, data) {
    return http.patch(`/product_variant/edit/${id}`, data);
  }
  ProductDeleteVariant(id) {
    return http.delete(`/product_variant/delete/${id}`);
  }
  VarientById(id) {
    return http.get(`/product_variant/id/${id}`);
  }

  // user management
  getAllUser() {
    return http.get(`/user/user/all`);
  }
  // Distributor
  getAllDistributor(from, end) {
    const URL = `/distributor/all`;
    const FILTERURL = `/distributor/all?startDate=${from}&endDate=${end}`;
    return http.get(`${end ? FILTERURL : URL}`);
  }
  updateDistributor(id, data) {
    return http.patch(`/distributor/admin/update/` + id, data);
  }
  getAllDistributorChild(id) {
    return http.get(`/distributor/admin/child/` + id);
  }
  DistributorTransaction(id, from, end) {
    const URL = `/admin/transaction/distributor/${id}`;
    const FILTERURL = `/admin/transaction/distributor/${id}?startDate=${from}&endDate=${end}`;
    return http.get(`${end ? FILTERURL : URL}`);
  }
  DistributorRankHistory(id) {
    return http.get(`/distributor/admin/rankhistory/` + id);
  }
  getAllRank() {
    return http.get(`/admin/rank/all`);
  }
  getAllRankrequest() {
    return http.get(`/admin/rankUpgrade/request/all`);
  }
  UpgradeRankrequest(data) {
    return http.post(`/admin/rankUpgrade`, data);
  }
  deleteUser(Id) {
    return http.delete(`/user/rankUpgrade/admin/${Id}`);
  }
  blockUnBlock(Id, data) {
    return http.patch(`/user/update/${Id}`, data);
  }
  // order management
  getAllOrder(from, end) {
    const URL = `/order/admin/all`;
    const FILTERURL = `/order/admin/all?startDate=${from}&endDate=${end}`;
    return http.get(`${end ? FILTERURL : URL}`);
  }
  getAllTransaction(from, end) {
    const URL = `/admin/transaction/all`;
    const FILTERURL = `/admin/transaction/all?startDate=${from}&endDate=${end}`;
    return http.get(`${end ? FILTERURL : URL}`);
  }
  getAllOrderTransaction(id) {
    return http.get(`/order/transaction/` + id);
  }
  getOrderByUser(id) {
    return http.get(`/order/distributor/` + id);
  }
  updateProudctOrder(Id, data) {
    console.log(data);
    return http.patch(`/order/update/${Id}`, data);
  }
  // pujas orders

  deleteOrder(Id) {
    return http.delete(`/order/delete/${Id}`);
  }
  getOrderByID(id) {
    return http.get(`/order/user/${id}`);
  }
}

export default new DataService();
